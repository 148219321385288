 <template>
  <div class="container-booking mt-6">
    <div class="title-notification-reScheduled">
      <p class="subtitle-basier-regular">Re-scheduled <br> booking</p>
    </div>
    <div class="text-nunito-light content-seSchedule">
      <p class="mb-5">Hi, <br> You have a booking for
      <span class="text-nunito-Regular">{{currentBooking.date}}</span>
      with the professional
      <span class="text-nunito-Regular">{{currentBooking.nameProfessional}}
      </span> to
      <span class="text-nunito-Regular">{{currentBooking.time}}</span>.</p>
      <p class="mb-5">Once you reschedule the booking, this date will be available again and your reservation will be canceled to generate a new schedule.</p>
      <p class="mb-6">If you want to reschedule, choose the date and time for your new booking.</p>
    </div>

    <button
    class="button-primary-extra-medium text-basier"
    @click="nextStep">
      Ok, let's do it
    </button>

  </div>
</template>

<script>
export default {
  name: 'ResheduleWelcome',
  props: {
    currentBooking: Object
  },
  created () {
  },
  methods: {
    // Send data to be sent by booking component
    nextStep () {
      this.notify.$emit('nextStep')
    }
  }
}
</script>

<style>
.title-notification-reScheduled {
  margin-bottom: 29px;
}
.title-notification-reScheduled p {
  color: #0C0D34;
}
.content-seSchedule p{
  font-size: 14px;
}
</style>
