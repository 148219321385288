<template>
  <div>
    <div v-if="bookingInfo" class="container-content-booking">

      <!-- status diferentes a el estatus cancelado -->
      <div
        v-if="this.status !== 'cancelled' && !this.professionalCancelInRequest && !this.clientCancelInApproved"
        class="container-info-booking"
        >
        <div
          class="status-date mt-5">
          <span
            v-if="this.typeOfUser === 'professional' && this.status === 'request'"
            class="text-basier gray-emitus status-text"
            style="background: rgba(255, 209, 154, 0.58);">
            Por aprobar
          </span>
          <span
            v-if="this.typeOfUser === 'client' && this.status === 'request'"
            class="text-basier gray-emitus status-text"
            style="background: rgba(110, 61, 234, 0.19);">
            Por ser aprobada
            </span>
          <span
            v-if="this.status === 'booked'"
            class="text-basier gray-emitus status-text"
            style="background: rgba(0, 193, 177, 0.22);">
            Aprobada
          </span>
          <span
            v-if="this.status === 'delivered'"
            class="text-basier gray-emitus status-text"
            style="background: rgba(0, 193, 177, 0.22);">
            Completada
          </span>
          <p
            class="text-basier dark-blue-emitus date-booking">
            {{this.bookingInfo.date}}
          </p>
        </div>
        <div class="mt-6 pb-4">
          <div
            @click="pushRouteProfile"
            v-if="this.typeOfUser === 'professional'"
            class="mt-5 is-flex is-align-items-center">
            <img
              width="40px"
              height="40px"
              class="image-avatar-booking is-rounded"
              :src="this.bookingInfo.avatar"
            >

            <div>
              <div
                v-if="this.bookingInfo.nameClient && this.bookingInfo.nameClient !== 'undefined undefined'">
                <p
                  class="pl-5 text-basier dark-blue-emitus name-client" >
                  {{this.bookingInfo.nameClient}}
                </p>
              </div>
              <div
                v-if="!this.bookingInfo.nameClient || this.bookingInfo.nameClient === 'undefined undefined'">
                <p
                  class="pl-5 text-basier dark-blue-emitus name-client" >
                  Client
                </p>
              </div>
              <div
                v-if="this.bookingInfo.titleClient">
                <p
                  class="pl-5 pt-1 text-basier gray-emitus title-client-booking" >
                  {{this.bookingInfo.titleClient}}
                </p>
              </div>
            </div>
          </div>
          <div
            @click="pushRouteProfile"
            v-if="this.typeOfUser === 'client'"
            class="mt-6 is-flex is-align-items-center">

            <img
              width="40px"
              height="40px"
              class="image-avatar-booking is-rounded"
              :src="this.bookingInfo.avatar"
            >

            <div>
              <div v-if="this.bookingInfo.nameProfessional && this.bookingInfo.nameProfessional !== 'undefined undefined'">
                <p class="pl-5 text-basier dark-blue-emitus name-professional">{{this.bookingInfo.nameProfessional}}</p>
              </div>
              <div v-if="!this.bookingInfo.nameProfessional || this.bookingInfo.nameProfessional === 'undefined undefined'">
                <p class="pl-5 text-basier dark-blue-emitus name-professional">Professional</p>
              </div>
              <div v-if="this.bookingInfo.titleProfessional">
                <p class="pl-5 pt-1 text-basier gray-emitus title-professional-booking" >{{this.bookingInfo.titleProfessional}}</p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="info-booking">
          <div
            class="mt-5 pt-3  top-line-gray is-flex is-justify-content-space-between is-align-items-center">
            <p
              class="pl-2 text-basier gray-emitus language-booking">
              {{this.bookingInfo.language}}
            </p>
            <span class="pr-2 text-nunito-SemiBold purple-dark-emitus price-booking">{{this.bookingInfo.price}} {{this.bookingInfo.currency === 'USD' ? '$' : '€'}}</span>
          </div>
          <div
            class="bottom-line-gray is-flex  is-justify-content-space-between mt-2 pb-5">
            <p
              class="pl-2 text-nunito dark-blue-emitus hour-booking">
              {{this.bookingInfo.time}}
            </p>

            <!--
            <span
              class="pr-2 time-for-videocall text-nunito gray-emitus"
              v-if="this.valid24h && this.status !== 'delivered'">
              Videocall / 20 min
            </span>-->
            <span
              class="
              pr-2
              duration-videocall-completed
              text-nunito
              gray-emitus"
              v-if="this.status === 'delivered'">
              Duration / {{this.booking.duration}} min
            </span>
          </div>

          <!-- P01 v-if Profesional | Status request -->
          <div  class="mt-6 pt-4 is-flex is-align-items-center is-justify-content-space-between" v-if="this.status === 'request' && this.typeOfUser === 'professional'">
            <button class="button-primary-medium" @click="approveBooking">Aprobar</button>
            <a class="text-basier gray-emitus cancel-button" @click="clickProfessionalCancelInRequest">Cancelar reserva</a>
          </div>

          <!--P02 v-if Cliente | Status request -->

          <div
            class="mt-6 pt-4 is-flex is-justify-content-space-between is-align-items-center"
            v-if="this.status === 'request' && this.typeOfUser === 'client'">
            <button
              v-if="this.status === 'request' && this.typeOfUser === 'client'"
              @click="showModal"
              class="button-secundary-small text-basier-medium">
              Re-agendada
            </button>
            <a
              class="mr-2 text-basier gray-emitus cancel-button"
              @click="cancelBookingBeforeApproval">
              Cancelar reserva
            </a>
          </div>

          <!-- P05 P06 | Profesional o Cliente | Status approved / booked -->
          <div v-if="this.status === 'booked' && !this.valid24h && !this.clientCancelInApproved">
            <!-- <div class="mt-5">
              <p v-if="!delivered" class="pl-2 text-nunito dark-blue-emitus">Follow the link for your video call: <a class="link-videocall" href="#">https://emitus.com/loij78087655hhmk</a></p>
            </div> -->
            <div class="mt-6 is-flex" v-if="this.typeOfUser === 'professional'">
              <p class="pl-2 text-nunito-SemiBold" >Add to:</p>
              <a class="ml-2 purple-dark-emitus link-add-calendar text-nunito-SemiBold" :href="bookingInfoProfessional.googleLink" target="_blank">Google Cal</a>
              <a class="ml-4 purple-dark-emitus link-add-calendar text-nunito-SemiBold" :href="bookingInfoProfessional.outlookLink" target="_blank">Outlook</a>
            </div>
            <div class="mt-6 is-flex" v-if="this.typeOfUser === 'client'" >
              <p class="pl-2 text-nunito-SemiBold">Add to:</p>
              <a class="ml-2 purple-dark-emitus link-add-calendar text-nunito-SemiBold" :href="bookingInfoClient.googleLink" target="_blank">Google Cal</a>
              <a class="ml-4 purple-dark-emitus link-add-calendar text-nunito-SemiBold" :href="bookingInfoClient.outlookLink" target="_blank">Outlook</a>
            </div>
            <!-- P05 v-if | Profesional -->
            <div class="mt-6" v-if="this.typeOfUser === 'professional'">
              <!-- si soy profesional me lleva a newCancelReason -->
              <button @click="clickProfessionalCancelInApproved" class="button-secundary-medium ">Cancelar reserva</button>
            </div>

            <!-- P06 v-if | Cliente -->
            <div class="mt-6 is-flex is-justify-content-space-between is-align-items-center container-buttons-reschedule" v-if="this.typeOfUser === 'client'" >
              <button
                v-if="(this.status === 'request' || this.status === 'booked') && this.typeOfUser === 'client'"
                @click="showModal"
                class="ml-2 button-secundary-small text-basier-medium">
                Re-agendar
              </button>
              <!-- si soy cliente me lleva a modal chatear soporte -->
              <a class="mr-2 cancel-button-disabled text-basier" @click="clickClientCancelInApproved">Cancelar reserva</a>
            </div>
            <!-- btn videocall -->
          </div>

          <!--P09 P10 v-if Profesional o Cliente | Booking esta a menos de 24 horas -->

          <div v-if="this.status === 'booked' && (this.typeOfUser === 'professional' || this.typeOfUser === 'client') && this.valid24h">
            <div :class="ready ? 'btn-videocall' : 'btn-videocall-disabled'" class="mt-6">
              <button
              @click="handlerGoToVideoCall"><p class="text-basier">Ir a videollamada</p></button>
            </div>
          </div>

          <!--P09 P10 Profesional o Cliente | status completado  -->
          <div :key="statusDelivered" class="mt-5" id="booking_completed" v-if="this.status === 'delivered' && (this.typeOfUser === 'professional' || this.typeOfUser === 'client')">
            <span class="ml-2 mb-5 pt-6 text-basier dark-blue-emitus">Review</span>
            <div class="mt-5 is-flex  is-justify-content-space-between is-align-items-center ">
              <p class="Basier-normal-Medium dark-blue-emitus pl-2">{{this.bookingInfo.nameClient}}</p>
              <div class="mr-2 is-flex">
                <input
                  class="value-rate Basier-normal-Medium ml-2"
                  v-model="booking.rateprofessional" min="0" :max="maxs"
                  :step="isDisabled ? '.1' : '1'"
                  disabled>
                <b-rate
                  size="is-small"
                  v-model="booking.rateprofessional"
                  :max="maxs"
                  :disabled="isDisabled">
                </b-rate>
              </div>
            </div>
            <p class="mt-5 ml-2 paragraph-content dark-gray-emitus">{{this.bookingInfo.nameClient}}, {{this.reviewReason}}</p>
          </div>

          <!--P09 P10 v-if Profesional o Cliente | Booking esta a menos de 24 horas -->

          <div v-if="ready">
            <!--
            <div class="mt-5">
              <p class="pl-2 text-nunito dark-blue-emitus">Follow the link for your video call: <a class="link-videocall" href="#">https://emitus.com/loij78087655hhmk</a></p>
            </div>-->
            <div class="mt-6 is-flex" v-if="this.typeOfUser === 'professional'" >
              <p class="pl-2 text-nunito-SemiBold" >Añadir a: </p>
              <a class="ml-2 purple-dark-emitus link-add-calendar text-nunito-SemiBold" :href="bookingInfoProfessional.googleLink" target="_blank">Google Cal</a>
              <a class="ml-4 purple-dark-emitus link-add-calendar text-nunito-SemiBold" :href="bookingInfoProfessional.outlookLink" target="_blank">Outlook</a>
            </div>
            <div class="mt-6 is-flex" v-if="this.typeOfUser === 'client'" >
              <p class="pl-2 text-nunito-SemiBold">Añadir a: </p>
              <a class="ml-2 purple-dark-emitus link-add-calendar text-nunito-SemiBold" :href="bookingInfoClient.googleLink" target="_blank">Google Cal</a>
              <a class="ml-4 purple-dark-emitus link-add-calendar text-nunito-SemiBold" :href="bookingInfoClient.outlookLink" target="_blank">Outlook</a>
            </div>
            <div class="mt-6 btn-videocall">
              <button
                @click="handlerGoToVideoCall"
                class="button-primary-extra-medium">Ir a videollamada</button>
            </div>
          </div>

        </div>
      </div>

      <!-- Screen cuando soy profesional, estoy en request y cancelo  -->

      <div
        v-if="this.status === 'request' && this.typeOfUser === 'professional' && this.professionalCancelInRequest"
        class="professional-cancel-booking"
      >
        <div
          class="container-info-modal">
          <div
            style="text-align: end; cursor: pointer;">
            <img
              @click="clickHiddeProfessionalCancelInRequest"
              src="@/assets/images/money/cancel.svg"
              alt=""
            >
          </div>
          <div>
            <img
              width="190px"
              src="https://email.emitus.com/NotBooking_cancelled.png"
              alt=""
            >
            <p
              class="mt-5 ml-2 text-basier dark-blue-emitus title-notification-large">
              ¿Estás segur@?
            </p>
            <p
              class="mt-5 ml-2 paragraph-content dark-gray-emitus paragraph-max-width">
              Estás <span class="text-nunito-SemiBold dark-blue-emitus">perdiendo {{this.bookingInfo.price}} {{this.bookingInfo.currency === 'USD' ? '$' : '€'}}</span>. Por favor cuéntale a <span class="text-tranformation-capitalize text-nunito-Regular dark-blue-emitus">{{this.bookingInfo.nameClient}}</span> la razón por la cual cancelas la sesión.
            </p>
          </div>

          <p class="
            mt-6 ml-2 text-basier sub-title-notification purple-dark-emitus">
            Cuéntale a <span class="text-tranformation-capitalize">{{this.bookingInfo.nameClient}}</span> por qué
          </p>
          <div
            class="mt-6 pl-2">
            <form>
              <label
                class="label-booking-canceled pb-3 text-basier gray-emitus">
                Razón de cancelación
              </label>
              <b-field>
                <b-input
                  v-model="newCancelReason"
                  maxlength="200"
                  type="textarea">
                </b-input>
              </b-field>
            </form>
          </div>
          <div
            v-if="this.status === 'request'"
            class="mt-4 ml-2 is-flex is-justify-content-space-between">
            <button
              class="button-primary-extra-medium"
              @click="cancelBookingBeforeApproval">
              <p
                class="text-basier-medium">
                Cancelar la reserva
                </p>
            </button>
            <a
              class="text-basier gray-emitus cancel-button pr-2" @click="clickHiddeProfessionalCancelInRequest">
              Volver
            </a>
          </div>
          <div
            v-if="this.status === 'booked'"
            class="mt-4 ml-2 is-flex is-justify-content-space-between">
            <button
              class="button-primary-extra-medium" @click="cancelBookingAfterApproval">
              <p
                class="text-basier">Cancelar reserva
              </p>
            </button>
            <a class="text-basier gray-emitus cancel-button" @click="clickHiddeProfessionalCancelInRequest">Volver</a>
          </div>
        </div>
      </div>
      <!-- Screen cuando soy profesional, estoy en approved y cancelo  -->
      <div
        v-if="this.status === 'booked' && this.typeOfUser === 'professional' && this.professionalCancelInApproved"
        class="professional-cancel-booking"
      >
        <div
          class="container-info-modal">
          <div
            style="text-align: end; cursor: pointer;">
            <img
              @click="clickHiddeProfessionalCancelInApproved"
              src="@/assets/images/money/cancel.svg"
              alt=""
            >
          </div>
          <div>
            <img
              width="190px"
              src="https://email.emitus.com/NotBooking_cancelled.png"
              alt=""
            >
            <p
              class="mt-5 ml-2 text-basier dark-blue-emitus title-notification-large">
              ¿Estás segur@?
            </p>
            <p
              class="mt-5 ml-2 paragraph-content dark-gray-emitus paragraph-max-width">
              Ya haz aprobado esta sesión y se ha cargado la tarjeta del cliente. Tendrías que asumir los costos de transacción.
            </p>
          </div>
          <div
            class="transaction-cost mt-6 py-4 is-flex is-align-items-center">
            <p
              class="ml-2 Basier-small-bold">
              Tarifa por cancelación tardía</p>
            <p
              class="mr-2 text-basier-medium"
              style="font-size: 19px; color: #6E3DEA;">
              {{this.cardFee.toFixed(2)}} {{this.bookingInfo.currency === 'USD' ? '$' : '€'}}
            </p>
          </div>

          <p class="
            mt-6 ml-2 text-basier sub-title-notification purple-dark-emitus">
            Cuéntale a <span class="text-tranformation-capitalize">{{this.bookingInfo.nameClient}}</span> por qué?
          </p>
          <div
            class="mt-6 pl-2">
            <form>
              <label
                class="label-booking-canceled pb-3 text-basier gray-emitus">
                Razón de cancelación
              </label>
              <b-field>
                <b-input
                  v-model="newCancelReason"
                  maxlength="200"
                  type="textarea">
                </b-input>
              </b-field>
            </form>
          </div>
          <div
            v-if="this.status === 'request'"
            class="mt-4 ml-2 is-flex is-justify-content-space-between">
            <button
              class="button-primary-extra-medium"
              @click="cancelBookingBeforeApproval">
              <p
                class="text-basier-medium">
                Cancelar reserva
                </p>
            </button>
            <a
              class="text-basier gray-emitus cancel-button pr-2" @click="clickHiddeProfessionalCancelInApproved">
              Volver
            </a>
          </div>
          <div
            v-if="this.status === 'booked'"
            class="mt-4 ml-2 is-flex is-justify-content-space-between">
            <button
              class="button-primary-extra-medium" @click="cancelBookingAfterApproval">
              <p
                class="text-basier">Cancelar reserva
              </p>
            </button>
            <a class="text-basier gray-emitus cancel-button" @click="clickHiddeProfessionalCancelInApproved">Volver</a>
          </div>
        </div>
      </div>

      <!-- Screen cancelación cliente | Cuando estoy aprobado y cancelo -->
      <div
        v-if="this.status === 'booked' && !this.valid24h && this.clientCancelInApproved"
        class="professional-cancel-booking">
        <div class="container-info-booking">
          <div
            style="text-align: end; cursor: pointer;">
            <img
              @click="clickHiddeClientCancelInApproved"
              src="@/assets/images/money/cancel.svg"
              alt=""
            >
          </div>
          <div>
            <img
              width="190px"
              src="https://email.emitus.com/NotBooking_cancelled.png"
              alt="">
            <p
              class="mt-5 ml-2 text-basier dark-blue-emitus title-notification-large">
              ¿Estás segur@?
            </p>
            <p
              class="mt-5 ml-2 paragraph-content dark-gray-emitus paragraph-max-width">
              Tu reserva ya había sido aprobada. Tendrías que asumir los costos de transacción.
            </p>
          </div>
          <div
            class="transaction-cost mt-6 py-4 is-flex is-align-items-center">
            <p
              class="ml-2 Basier-small-bold">
              Tarifa por cancelación tardía</p>
            <p
              class="mr-2 text-basier-medium"
              style="font-size: 19px; color: #6E3DEA;">
              {{this.cardFee.toFixed(2)}} {{this.bookingInfo.currency === 'USD' ? '$' : '€'}}
            </p>
          </div>
          <div
            class="mt-6 ml-2 is-flex is-justify-content-space-between">
            <button
              class="button-primary-extra-medium" @click="cancelBookingAfterApproval">
              <span
                class="text-basier-medium">
                Si, continuar
              </span>
            </button>
            <a
              class="mr-3 text-basier gray-emitus cancel-button" @click="clickHiddeClientCancelInApproved">
              Volver
            </a>
          </div>
        </div>
      </div>

      <!-- status cuando esta en cancelado -->

      <!-- P03 Profesional | Booking cancelado por no aprobar en 24 horas -->
      <div
        v-if="this.status === 'cancelled' && !this.whocancel && this.typeOfUser === 'professional'" class="container-info-booking">
        <div>
          <span
            class="text-basier gray-emitus status-text"
            style="background: #FFD6E0;">
            Haz perdido
          </span>
          <p
            class="text-basier dark-blue-emitus date-booking">
            {{this.bookingInfo.date}}
          </p>
          <!-- <p
            class="mt-5 ml-2 text-basier dark-blue-emitus title-notification">
            You’ve lost {{this.bookingInfo.price}} {{this.bookingInfo.currency === 'USD' ? '$' : '€'}} !
          </p> -->
          <!-- <p
            class="mt-5 ml-2 paragraph-content dark-gray-emitus">
            <span class="text-basier-SemiBold">Tienes 24 horas para confirmar una solicitud de reserva.</span> Intente confirmar su reserva lo antes posible para evitar que esto vuelva a suceder. Confirme una solicitud de reserva.</p> -->
        </div>
        <div
          @click="pushRouteProfile"
          class="mt-6 is-flex is-align-items-center">
          <img
            width="40px"
            height="40px"
            class="is-rounded image-avatar-booking"
            :src="this.bookingInfo.avatar" >

          <div>
            <div
              v-if="this.bookingInfo.nameClient && this.bookingInfo.nameClient !== 'undefined undefined'">
              <p
                class="pl-5 text-basier dark-blue-emitus name-client" >
                {{this.bookingInfo.nameClient}}
              </p>
            </div>
            <div
              v-if="!this.bookingInfo.nameClient || this.bookingInfo.nameClient === 'undefined undefined'">
              <p
                class="pl-5 text-basier dark-blue-emitus name-client" >
                Client
              </p>
            </div>
            <div
              v-if="this.bookingInfo.titleClient">
              <p
                class="pl-5 pt-1 text-basier gray-emitus title-client-booking" >
                {{this.bookingInfo.titleClient}}
              </p>
            </div>
          </div>
        </div>
        <div
          class="mt-6 pb-5" style="border-bottom: 1px solid #ECECEC">
          <div
            class="pt-5 top-line-gray is-flex is-justify-content-space-between">
            <p
              class="pl-2 Basier-normal-Medium dark-blue-emitus">
              Reserva por cancelar
            </p>
            <span
              class="pl-2 text-nunito-SemiBold money-lost-booking decoration-line-through">
              {{this.bookingInfo.price}} {{this.bookingInfo.currency === 'USD' ? '$' : '€'}}
            </span>
          </div>
          <div
            class="mt-5 is-flex is-justify-content-space-between is-align-items-center">
            <div
              class="ml-2 is-flex">
              <img
                class="mr-2" src="https://emituscdn.com/calendar-vector.svg"
                alt=""
                width="14px">
              <p
                class="info-canceled text-nunito dark-blue-emitus">
                {{this.bookingInfo.date}}
              </p>
            </div>
            <p
              class="pr-2 duration-videocall-completed text-nunito gray-emitus">
              {{this.booking.duration}} min
            </p>
          </div>
          <div class="mt-3 is-flex">
            <img class="ml-2 mr-2" src="https://emituscdn.com/clock-vector.svg" alt="" width="15px">
            <p class="info-canceled text-nunito dark-blue-emitus">{{this.bookingInfo.time}}</p>
          </div>
        </div>
        <p
          class="mt-5 ml-2 paragraph-content dark-gray-emitus">
          <span class="text-basier-SemiBold">Tienes 24 horas para confirmar una solicitud de reserva.</span> Intenta confirmar la reserva lo antes posible para evitar que esto vuelva a suceder. Confirma la solicitud de reserva.</p>
      </div>



      <!--P04 v-if Como cliente | el booking se cancela por no ser aprobado en 24 horas -->

      <div
        v-if="this.status === 'cancelled' && !this.whocancel && this.typeOfUser === 'client'"
        class="container-info-booking">
        <div>
          <span
            class="text-basier gray-emitus status-text"
            style="background: #ECECEC;">
            Cancelada
          </span>
          <p
            class="text-basier dark-blue-emitus date-booking">
            {{this.bookingInfo.date}}
          </p>
          <!-- <p
            class="mt-5 ml-2 paragraph-content dark-gray-emitus">
            <span
              class="text-nunito-SemiBold dark-blue-emitus">
              {{(this.bookingInfo.nameProfessional && this.bookingInfo.nameProfessional !== 'undefined undefined') ? this.bookingInfo.nameProfessional : 'Profesional'}}
              </span>
              was not able to  confirm the booking. Would you like to try a different date?
          </p> -->
        </div>
        <div
          @click="pushRouteProfile"
          class="mt-6 bottom-line-gray is-flex is-align-items-center">

          <img
            width="40px"
            height="40px"
            class="is-rounded image-avatar-booking"
            :src="this.bookingInfo.avatar"
          >

          <div>
            <div
              v-if="this.bookingInfo.nameProfessional && this.bookingInfo.nameProfessional !== 'undefined undefined'">
              <p
                class="pl-5 text-basier dark-blue-emitus name-professional">
                {{this.bookingInfo.nameProfessional}}
              </p>
            </div>
            <div
              v-if="!this.bookingInfo.nameProfessional || this.bookingInfo.nameProfessional === 'undefined undefined'">
              <p
                class="pl-5 text-basier dark-blue-emitus name-professional" >
                Professional
              </p>
            </div>
            <div
              v-if="this.bookingInfo.titleProfessional">
              <p
                class="pl-5 pt-1 text-basier gray-emitus title-professional-booking" >
                {{this.bookingInfo.titleProfessional}}
              </p>
            </div>
          </div>
        </div>
        <div
          class="mt-6 pb-5" style="border-bottom: 1px solid #ECECEC">
          <div
            class="pt-5 top-line-gray is-flex is-justify-content-space-between">
            <p
              class="pl-2 Basier-normal-Medium dark-blue-emitus">
              Reserva por cancelar
            </p>
            <span
              class="pr-2 text-nunito dark-blue-emitus">
              {{this.booking.duration}} min
            </span>
          </div>
          <div
            class="mt-5 is-flex">
            <img
              class="ml-2 mr-2" src="https://emituscdn.com/calendar-vector.svg"
              alt=""
              width="14px"
            >
            <p
              class="info-canceled text-nunito dark-blue-emitus">
              {{this.bookingInfo.date}}
            </p>
          </div>
          <div class="mt-3 is-flex">
            <img
              class="ml-2 mr-2 "
              src="https://emituscdn.com/clock-vector.svg"
              alt=""
              width="15px">
            <p
              class="info-canceled text-nunito dark-blue-emitus">
              {{this.bookingInfo.time}}
            </p>
          </div>
          <p
            class="mt-5 ml-2 paragraph-content dark-gray-emitus">{{this.bookingInfo.nameProfessional}} was not able to  confirm the booking on time. Would you like to try a different date?</p>
        </div>
        <div class="mt-6">
          <button
            class="button-primary-extra-medium">
            Trata una nueva fecha
          </button>
        </div>
      </div>

      <!-- P11 Professional | cliente cancela -->
      <div
        v-if="this.status === 'cancelled' && this.whocancel === 'client' && this.typeOfUser === 'professional'"
        class="container-info-booking">
        <div>
          <span
            class="text-basier gray-emitus status-text"
            style="background: #ECECEC;">
            Cancelled
          </span>
          <p
            class="text-basier dark-blue-emitus date-booking">
            {{this.bookingInfo.date}}
          </p>
        </div>
        <div
          @click="pushRouteProfile"
          class="mt-6 is-flex is-align-items-center">

          <img
            width="40px" height="40px"
            class="is-rounded image-avatar-booking"
            :src="this.bookingInfo.avatar"
          >

          <div>
            <div
              v-if="this.bookingInfo.nameClient && this.bookingInfo.nameClient !== 'undefined undefined'">
              <p
                class="pl-4 text-basier dark-blue-emitus name-client" >
                {{this.bookingInfo.nameClient}}
              </p>
            </div>
            <div
              v-if="!this.bookingInfo.nameClient || this.bookingInfo.nameClient === 'undefined undefined'">
              <p
                class="pl-4 text-basier dark-blue-emitus name-client" >
                Client
              </p>
            </div>
            <div
              v-if="this.bookingInfo.titleClient">
              <p
                class="pl-4 pt-1 text-basier gray-emitus title-client-booking" >
                {{this.bookingInfo.titleClient}}
              </p>
            </div>
          </div>
        </div>
        <div
          class="mt-6 bottom-line-gray pb-3">
          <div
            class="pt-4 top-line-gray is-flex is-justify-content-space-between">
            <p
              class="pl-2 Basier-normal-Medium dark-blue-emitus">
              Sesión cancelada
            </p>
            <span
              class="pr-2 text-nunito-SemiBold dark-blue-emitus decoration-line-through">
              {{this.bookingInfo.price}} {{this.bookingInfo.currency === 'USD' ? '$' : '€'}}
            </span>
          </div>
          <div
            class="mt-5 is-flex is-justify-content-space-between is-align-items-center">
            <div
              class="ml-2 is-flex">
              <img
                class="mr-2" src="https://emituscdn.com/calendar-vector.svg" alt="" width="14px">
              <p
                class="info-canceled text-nunito dark-blue-emitus">
                {{this.bookingInfo.date}}
              </p>
            </div>
            <span
              class="pr-2 text-nunito gray-emitus duration-videocall-completed">
              {{this.booking.duration}} min
            </span>
          </div>
          <div
            class="mt-3 is-flex">
            <img
              class="ml-2 mr-2 "
              src="https://emituscdn.com/clock-vector.svg"
              alt=""
              width="15px"
            >
            <p class="info-canceled text-nunito dark-blue-emitus">{{this.bookingInfo.time}}</p>
          </div>
        </div>
        <p
          class="ml-2 mt-6 text-nunito dark-gray-emitus">
          <span class="text-nunito-Regular dark-blue-emitus">Esta reserva ha sido cancelada por el cliente 😓 .</span>
        </p>
      </div>

      <!-- P12 Cliente | Cancelo el booking -->

      <div
        v-if="this.status === 'cancelled' && this.whocancel === 'client' && this.typeOfUser === 'client'"
        class="container-info-booking">
        <div class="mt-6">
          <span
            class="text-basier gray-emitus status-text"
            style="background: #ECECEC;">
            Cancelada
          </span>
          <p
            class="text-basier dark-blue-emitus date-booking">
            {{this.bookingInfo.date}}
          </p>
        </div>
        <div
          @click="pushRouteProfile"
          class="mt-6 is-flex is-align-items-center">

          <img
            width="40px"
            height="40px"
            class="is-rounded image-avatar-booking"
            :src="this.bookingInfo.avatar"
          >

          <div>
            <div
              v-if="this.bookingInfo.nameProfessional && this.bookingInfo.nameProfessional !== 'undefined undefined'">
              <p
                class="pl-4 text-basier dark-blue-emitus name-professional">
                {{this.bookingInfo.nameProfessional}}
              </p>
            </div>
            <div
              v-if="!this.bookingInfo.nameProfessional || this.bookingInfo.nameProfessional === 'undefined undefined'">
              <p
                class="pl-4 text-basier dark-blue-emitus name-professional" >
                Abogado
              </p>
            </div>
            <div
              v-if="this.bookingInfo.titleProfessional">
              <p
                class="pl-4 pt-1 text-basier gray-emitus title-professional-booking" >
                {{this.bookingInfo.titleProfessional}}
              </p>
            </div>
          </div>
        </div>
        <div
          class="mt-6 bottom-line-gray pb-3">
          <div
            class="pt-4 top-line-gray is-flex is-justify-content-space-between">
            <p
              class="pl-2 Basier-normal-Medium dark-blue-emitus">
              Sesión cancelada
            </p>
            <span
              class="pr-2 text-nunito dark-blue-emitus"
              style="text-decoration: line-through; font-weight: bold;">
              {{this.booking.price}} {{this.bookingInfo.currency === 'USD' ? '$' : '€'}}
            </span>
          </div>
          <div
            class="mt-3 is-flex is-justify-content-space-between">
            <div
              class="is-flex">
              <img
                class="ml-2 mr-2"
                src="https://emituscdn.com/calendar-vector.svg"
                alt=""
                width="14px"
              >
              <p
                class="info-canceled text-nunito dark-gray-emitus">
                {{this.bookingInfo.date}}
              </p>
            </div>
            <div>
              <span class="pr-2 text-nunito dark-blue-emitus">{{this.booking.duration}} min</span>
            </div>
          </div>
          <div class="mt-3 is-flex">
            <img  class="ml-2 mr-2 " src="https://emituscdn.com/clock-vector.svg" alt="" width="15px">
            <p class="info-canceled text-nunito dark-gray-emitus">{{this.bookingInfo.time}}</p>
          </div>
        </div>
        <p class="ml-2 mt-6 text-nunito dark-gray-emitus"><span class="text-nunito-Regular dark-blue-emitus">Has cancelado esta reserva.</span> Esperamos verte pronto 😚</p>
      </div>

      <!--P13 Profesional | Cancelo Booking -->

      <div
        v-if="this.status === 'cancelled' && this.whocancel === 'professional' && this.typeOfUser === 'professional'"
        class="container-info-booking">
        <div
          class="ml-2 mt-6">
          <span
            class="text-basier gray-emitus status-text"
            style="background: #ECECEC;">
            Cancelada
          </span>
          <p
            class="text-basier dark-blue-emitus date-booking">
            {{this.bookingInfo.date}}
          </p>
        </div>
        <div
          @click="pushRouteProfile"
          class="mt-6 is-flex is-align-items-center">

          <img
            width="40px"
            height="40px"
            class="is-rounded image-avatar-booking"
            :src="this.bookingInfo.avatar"
          >

          <div>
            <div
              v-if="this.bookingInfo.nameClient && this.bookingInfo.nameClient !== 'undefined undefined'">
              <p
                class="pl-4 text-basier dark-blue-emitus name-client" >
                {{this.bookingInfo.nameClient}}
              </p>
            </div>
            <div
              v-if="!this.bookingInfo.nameClient || this.bookingInfo.nameClient === 'undefined undefined'">
              <p
                class="pl-4 text-basier dark-blue-emitus name-client" >
                Client
              </p>
            </div>
            <div
              v-if="this.bookingInfo.titleClient">
              <p
                class="pl-5 pt-1 text-basier gray-emitus title-client-booking" >
                {{this.bookingInfo.titleClient}}
              </p>
            </div>
          </div>
        </div>
        <div
          class="mt-6 bottom-line-gray pb-3">
          <div
            class="pt-4 top-line-gray is-flex is-justify-content-space-between">
            <p
              class="pl-2 Basier-normal-Medium dark-blue-emitus">
              Sesión cancelada
            </p>
            <span
              class="pr-2 text-nunito-SemiBold dark-blue-emitus decoration-line-through">
              {{this.bookingInfo.price}} {{this.bookingInfo.currency === 'USD' ? '$' : '€'}}
            </span>
          </div>
          <div
            class="mt-5 is-flex is-justify-content-space-between is-align-items-center">
            <div
              class="ml-2 is-flex">
              <img
                class="mr-2"
                src="https://emituscdn.com/calendar-vector.svg"
                alt=""
                width="14px">
              <p
                class="info-canceled text-nunito dark-blue-emitus">
                {{this.bookingInfo.date}}
              </p>
            </div>
            <span
              class="mr-2 text-nunito gray-emitus duration-videocall-completed">
              {{this.booking.duration}} min
            </span>
          </div>
          <div
            class="mt-5 is-flex">
            <img
              class="ml-2 mr-2 "
              src="https://emituscdn.com/clock-vector.svg"
              alt=""
              width="15px"
            >
            <p class="info-canceled text-nunito dark-blue-emitus">{{this.bookingInfo.time}}</p>
          </div>
        </div>
        <div class="mt-6">
          <p class="ml-2 text-basier dark-blue-emitus">Reason cancel</p>
          <p class="mt-5 ml-2 paragraph-content dark-gray-emitus">{{this.cancelreason}}</p>
        </div>
      </div>

      <!-- P14 Cliente | El profesional cancela -->
      <div
        v-if="this.status === 'cancelled' && this.whocancel === 'professional' && this.typeOfUser === 'client'"
        class="container-info-booking">
        <div
          class="mt-6">
          <span
            class="text-basier gray-emitus status-text"
            style="background: #ECECEC;">
            Cancelada
          </span>
          <p
            class="text-basier dark-blue-emitus date-booking">
            {{this.bookingInfo.date}}
          </p>
        </div>
        <div
          @click="pushRouteProfile"
          class="mt-6 is-flex is-align-items-center">

          <img
            width="40px"
            height="40px"
            class="is-rounded image-avatar-booking"
            :src="this.bookingInfo.avatar"
          >

          <div>
            <div
              v-if="this.bookingInfo.nameProfessional && this.bookingInfo.nameProfessional !== 'undefined undefined'">
              <p
                class="pl-4 text-basier dark-blue-emitus name-professional">
                {{this.bookingInfo.nameProfessional}}
              </p>
            </div>
            <div
              v-if="!this.bookingInfo.nameProfessional || this.bookingInfo.nameProfessional === 'undefined undefined'">
              <p
                class="pl-4 text-basier dark-blue-emitus name-professional" >
                Professional
              </p>
            </div>
            <div
              v-if="this.bookingInfo.titleProfessional">
              <p
                class="pl-4 pt-1 text-basier gray-emitus title-professional-booking" >
                {{this.bookingInfo.titleProfessional}}
              </p>
            </div>
          </div>
        </div>
        <div
          class="mt-6 bottom-line-gray pb-3">
          <div
            class="pt-3 top-line-gray is-flex is-justify-content-space-between">
            <p
              class="pl-2 Basier-normal-Medium dark-blue-emitus">
              Sesión cancelada
            </p>
            <span
              class="pr-2 text-nunito-SemiBold dark-blue-emitus decoration-line-through">
              {{this.bookingInfo.price}} {{this.bookingInfo.currency === 'USD' ? '$' : '€'}}
            </span>
          </div>
          <div
            class="mt-5 is-flex is-justify-content-space-between">
            <div
              class="is-flex">
              <img
                class="ml-2 mr-2"
                src="https://emituscdn.com/calendar-vector.svg"
                alt=""
                width="14px">
              <p
                class="info-canceled text-nunito dark-blue-emitus">
                {{this.bookingInfo.date}}
              </p>
            </div>
            <div>
              <span class="pr-2 text-nunito dark-blue-emitus">{{this.booking.duration}} min</span>
            </div>
          </div>
          <div
            class="mt-3 is-flex">
            <img
              class="ml-2 mr-2 "
              src="https://emituscdn.com/clock-vector.svg"
              alt=""
              width="15px"
            >
            <p
              class="ml-1 info-canceled text-nunito dark-blue-emitus">
              {{this.bookingInfo.time}}
            </p>
          </div>
        </div>
        <div>
          <p
            class="mt-6 ml-2 paragraph-content dark-gray-emitus">
            <span
              class="text-tranformation-capitalize text-nunito-SemiBold dark-blue-emitus">
              😓 <span class="ml-2">{{(this.bookingInfo.nameProfessional && this.bookingInfo.nameProfessional !== 'undefined undefined') ? this.bookingInfo.nameProfessional : 'Profesional'}}</span> canceló porque:</span> ”{{this.cancelreason}}”</p>
        </div>
        <div class="mt-6">
          <button
            @click="showModal"
            class="button-primary-extra-medium text-basier-medium">
            Re-agendar
          </button>
        </div>
      </div>
    </div>
    <!-- Modal Booking -->
    <div v-if="modal" id="modal" style="position: fixed; top: 0; width: 100%; height: 100%; background: rgba(9, 13, 41, 0.95); display: flex; justify-content: space-between; z-index: 31;">
      <div  @click="hiddeModal" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: transparent"></div>
      <div class="is-flex  general-container">
        <div class="modal-container">
          <Reschedule
            :currentBooking="bookingInfo"
            :key="bookingKey"
          />
        </div>
      </div>
    </div>

    <!-- Videocall -->
    <Videocall
      v-if="showVideocall"
    />
      <!-- v-if="showVideocall" -->
    <div
      v-if="showVideocall"
      class="exitVideocall is-flex is-justify-content-space-between">
      <div>
        <img
          src="https://emituscdn.com/emitus-logo-gray.png"
          height="15px"
        >
      </div>
      <!--<div
        @click="exitVideocall"
      > Exit videocall</div>-->
      <div class="pr-6 pt-2 is-flex is-align-items-center">
        <span
          class="text-basier white pr-3">
          <span> {{timeReadyVideoCall}} min
          </span>
        </span>
        <svg
          width="9"
          height="13"
          viewBox="0 0 9 16"
          fill="none"
          class="mb-1"
        >
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.59186 1.45455C1.46712 1.45455 1.39008 1.59307 1.45425 1.70195L4.33333 6.58644L4.33454 6.58439L5.2008 8.06088L5.20138 8.05911L8.43771 13.5497C9.07334 14.6281 8.31024 16 7.0748 16H1.59186C0.356427 16 -0.406672 14.6281 0.228957 13.5497L3.50013 8L0.228957 2.4503C-0.406672 1.37193 0.356427 0 1.59186 0H7.0748C8.31024 0 9.07334 1.37193 8.43771 2.45031L5.59663 7.27033L4.76283 5.85778L7.21241 1.70195C7.27659 1.59307 7.19954 1.45455 7.0748 1.45455H1.59186ZM4.33333 9.41356L1.45425 14.2981C1.39008 14.4069 1.46712 14.5455 1.59186 14.5455H7.0748C7.19954 14.5455 7.27659 14.4069 7.21241 14.2981L4.33333 9.41356Z" fill="rgba(255, 255, 255, 0.6)"/>
        </svg>
      </div>
    </div>

    <!-- Rating -->
    <b-modal
      v-if="showRating && this.status === 'delivered'"
      v-model="showRating"
      style="z-index: 99"
      full-screen
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-label="Example Modal"
      aria-modal
      :can-cancel="false">

      <div>
        <Rating
          @close="showRating = false"
        />
      </div>
    </b-modal>

    <!-- loader -->
    <div v-if="loader">
      <Loading/>
    </div>

  </div>
</template>

<script>
import Loading from '@/components/modals/loader.vue'
import dayjs, { Dayjs } from 'dayjs'
import firebase from 'firebase/app'
import 'firebase/auth'
import BookingService from '@/services/BookingService'
import Reschedule from '@/components/booking/reschedule'
import UserService from '@/services/UserService'
import Videocall from '@/components/videocall/videocall.vue'
import Rating from '@/components/modals/rating.vue'

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
var localizedFormat = require('dayjs/plugin/localizedFormat')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)

export default {
  name: 'EditBooking',
  components: {
    Loading,
    Reschedule,
    Videocall,
    Rating
  },
  data () {
    return {
      booking: {},
      bookingInfo: null,
      authUser: null,
      user: {},
      newCancelReason: '',
      bookingInfoProfessional: null,
      bookingInfoClient: null,
      idToken: null,
      rate: 4.9,
      maxs: 1,
      isDisabled: true,
      day: new Date(),
      valid24h: false,
      professionalCancelInRequest: false,
      clientCancelInApproved: false,
      modal: false,
      bookingKey: 0,
      showVideocall: false,
      costTransaction: 0.30,
      timeReadyVideoCall: null,
      intervalVidecall: 0,
      showRating: false,
      professionalCancelInApproved: false,
      loader: false,
      statusDelivered: 0
      // ready: false

    }
  },
  computed: {
    typeOfUser: function () {
      if (this.booking.professional_id === this.user._id) {
        return 'professional'
      } else if (this.booking.client_id === this.user._id) {
        return 'client'
      } else {
        return ''
      }
    },

    status: function () {
      if (this.booking.status === 'ready') {
        if (this.timeReadyVideoCall > 0 || this.timeReadyVideoCall === null) {
          return this.booking.status
        } else {
          return 'delivered'
        }
      } else {
        return this.booking.status
      }
    },

    whocancel: function () {
      if (this.booking.whocancel !== undefined) {
        return this.booking.whocancel
      } else {
        return null
      }
    },
    reviewReason: function () {
      if (this.booking.reviewprofessional !== undefined) {
        return this.booking.reviewprofessional
      } else {
        return null
      }
    },
    cancelreason: function () {
      if (this.booking.cancelreason !== undefined) {
        return this.booking.cancelreason
      } else {
        return null
      }
    },

    delivered: function () {
      if (this.booking.status === 'delivered') {
        return true
      } else {
        return false
      }
    },

    ready: function () {
      if (this.booking.status === 'ready') {
        return true
      } else {
        return false
      }
    },

    cardFee: function () {
      if (this.bookingInfo.price * 0.03 < 0.45) {
        return 0.45
      } else {
        return this.bookingInfo.price * 0.03
      }
    }

  },
  async created () {
    // console.log('se crea el componente de estatus?')
    //
    // setTimeout(() => {
    //   if (this.typeOfUser === 'professional' && !this.booking.rateclient) {
    //     this.showRating = true
    //   } else if (this.typeOfUser === 'client' && !this.booking.rateprofessional) {
    //     this.showRating = true
    //   } else {
    //     this.showRating = false
    //   }
    // }, 1000)

    // Get User form this.notify
    this.notify.$on('updateAuthUser', (user) => {
      this.authUser = user
    })
    this.notify.$on('updateUser', (user) => {
      this.user = user
      // console.log(this.user, 'usuario logeado')
    })
    // Close modal
    this.notify.$on('close', () => {
      this.hiddeModal()
    })
    // Close rating
    this.notify.$on('closeRating', () => {
      this.showRating = false
    })
    // Exit videocall
    this.notify.$on('exitVideocall', () => {
      this.showVideocall = false
      clearInterval(this.intervalVidecall)
    })
    // refresh status delivered
    this.notify.$on('refreshStatusBooking', () => {
      ++this.statusDelivered
    })
    firebase.auth().currentUser.getIdToken(true)
      .then(async idToken => {
        const myBooking = await BookingService.getBooking(idToken, this.$route.params.idBooking)
        this.booking = myBooking.data
        this.notify.$emit('booking_', this.booking)
        const hourCurrent = dayjs(this.day)
        const hourSessionInit = dayjs(this.booking.startBook)
        const hourDiff = hourSessionInit.diff(hourCurrent, 'hour')
        // console.log(hourDiff, 'hora de diferencia')
        if (hourDiff <= 24) {
          this.valid24h = true
        }
        // console.log(myBooking.data)
        this.idToken = idToken
      })
      .catch(error => {
        console.log('No user logged', error)
      })
    setTimeout(() => {
      if (this.typeOfUser === 'professional' && !this.booking.rateclient) {
        // console.log(this.booking, '------------------------------')
        this.showRating = true
      } else if (this.typeOfUser === 'client' && !this.booking.rateprofessional) {
        // console.log(this.booking, '------------------------------')
        this.showRating = true
      } else {
        this.showRating = false
      }
    }, 1000)
  },
  async mounted () {
    // Ask for data to App
    await this.notify.$emit('giveMeData')
  },
  beforeDestroy () {
    this.notify.$off('updateAuthUser')
    this.notify.$off('updateUser')
    this.notify.$off('close')
    this.notify.$off('closeRating')
    this.notify.$off('exitVideocall')
  },
  watch: {
    // Get my Booking
    authUser: function () {
      if (this.authUser) {
        firebase.auth().currentUser.getIdToken(true)
          .then(async idToken => {
            const myBooking = await BookingService.getBooking(idToken, this.$route.params.idBooking)
            this.booking = myBooking.data
            this.notify.$emit('booking_', this.booking)
            const hourCurrent = dayjs(this.day)
            const hourSessionInit = dayjs(this.booking.startBook)
            const hourDiff = hourSessionInit.diff(hourCurrent, 'hour')
            // console.log(hourDiff, 'hora de diferencia')
            if (hourDiff <= 24) {
              this.valid24h = true
            }
            // console.log(myBooking.data)
            this.idToken = idToken
          })
          .catch(error => {
            console.log('No user logged', error)
          })
      } else {
        this.booking = {}
      }
    },
    typeOfUser: async function () {
      if (this.typeOfUser === 'professional') {
        const myBookingInfoProfessionalData = await BookingService.getMyBookingInfoProfessional(this.idToken, this.$route.params.idBooking)
        this.bookingInfoProfessional = myBookingInfoProfessionalData.data
        this.notify.$emit('booking_professional', this.bookingInfoProfessional)
        this.bookingInfo = this.bookingInfoProfessional
      } else if (this.typeOfUser === 'client') {
        const myBookingInfoClientData = await BookingService.getMyBookingInfoClient(this.idToken, this.$route.params.idBooking)
        this.bookingInfoClient = myBookingInfoClientData.data
        this.notify.$emit('booking_cliente', this.bookingInfoClient)
        this.bookingInfo = this.bookingInfoClient
      }
    }
  },
  methods: {
    pushRouteProfile () {
      if (this.bookingInfoProfessional) {
        location.href = '/' + this.bookingInfoProfessional.usernameClient
      }
      if (this.bookingInfoClient) {
        location.href = '/' + this.bookingInfoClient.usernameProfessional
      }
    },
    async approveBooking () {
      this.loader = true
      firebase.auth().currentUser.getIdToken(true)
        .then(async idToken => {
          try {
            await BookingService.approveBooking(idToken, this.$route.params.idBooking)
            // console.log('estra el approved aqui??? 1 !!')
            this.loader = false
            this.success('Booking approved')
            this.refresh()
          } catch (error) {
            this.loader = false
            this.danger(error.response.data.msg)
          }
        })
    },
    // Cancel bookings
    async cancelBookingBeforeApproval () {
      this.loader = true
      if (this.newCancelReason || this.typeOfUser === 'client') {
        firebase.auth().currentUser.getIdToken(true)
          .then(async idToken => {
            try {
              await BookingService.cancelBookingBeforeApproval(idToken, this.$route.params.idBooking, this.typeOfUser, this.newCancelReason)
              // console.log('estra el cancel aqui???')
              this.loader = false
              this.success('Booking cancelled')
              this.refresh()
            } catch (error) {
              this.loader = false
              this.danger(error.response.data.msg)
            }
          })
      } else {
        this.loader = false
        this.danger('Escribe la razón por la que cancelaste la sesión')
      }
    },
    async cancelBookingAfterApproval () {
      this.loader = true
      if (this.newCancelReason || this.typeOfUser === 'client') {
        firebase.auth().currentUser.getIdToken(true)
          .then(async idToken => {
            try {
              await BookingService.cancelBookingAfterApproval(idToken, this.$route.params.idBooking, this.typeOfUser, this.newCancelReason)
              this.loader = false
              this.success('Booking cancelled')
              this.refresh()
            } catch (error) {
              this.loader = false
              console.log('d', error)
              this.danger(error.response.data.msg)
            }
          })
      } else {
        this.loader = false
        this.danger('Escribe la razón por la que cancelaste la sesión')
      }
    },
    // cancel views status
    cancelViewsBooking () {
      this.$router.push('/panel/booking')
      // console.log(this.$router)
      this.booking = null
      this.bookingInfoProfessional = null
      this.bookingInfoClient = null
      this.notify.$emit('booking_', this.booking)
      this.notify.$emit('booking_professional', this.bookingInfoProfessional)
      this.notify.$emit('booking_cliente', this.bookingInfoClient)
    },

    // click btn videocall
    handlerGoToVideoCall () {
      // this.booking.status === 'ready'
      if (this.booking.status === 'ready') {
        const currentHour = dayjs().format('HH:mm')
        const hourSession = dayjs(this.booking.startBook).format('HH:mm')
        if (currentHour >= hourSession) {
          this.showVideocall = true
          if (this.typeOfUser === 'professional' || this.typeOfUser === 'client') {
            this.counterRegresive()
          }
        } else if (currentHour < hourSession) {
          this.danger('Todavía no ha llegado la hora de la videollamada')
        }
        // this.counterRegresive()
      } else {
        this.danger('Todavía no ha llegado la hora de la videollamada')
      }
    },
    // counter regresive
    counterRegresive () {
      if (this.showVideocall === true) {
        this.dataCounterRegresive()
        this.intervalVidecall = setInterval(() => {
          this.dataCounterRegresive()
        }, 60000)
      }
    },
    dataCounterRegresive () {
      const entryClient = dayjs(new Date()).utc()
      entryClient.local().format('hh:mm:ss')
      const endSessions = dayjs(this.booking.endBook).utc()
      endSessions.local().format('hh:mm:ss')
      const diffEntryClientAndEndeSessions = endSessions.diff(entryClient, 'minute')
      this.timeReadyVideoCall = diffEntryClientAndEndeSessions
    },
    // Refresh data
    async refresh () {
      firebase.auth().currentUser.getIdToken(true)
        .then(async idToken => {
          const myBooking = await BookingService.getBooking(idToken, this.$route.params.idBooking)
          this.booking = myBooking.data
        })
    },

    // Success Toast
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-danger'
      })
    },
    // Re-schedule modal
    showModal () {
      this.modal = true
      this.bookingKey++
      const publicProfile = document.body
      publicProfile.classList.toggle('noScroll')
    },
    hiddeModal () {
      this.modal = false
      const publicProfile = document.body
      publicProfile.classList.toggle('noScroll')
    },
    // soy profesional y cancelo cuando estoy en request
    clickProfessionalCancelInRequest () {
      this.professionalCancelInRequest = true
      const publicProfile = document.body
      publicProfile.classList.toggle('noScroll')
    },
    clickHiddeProfessionalCancelInRequest () {
      this.professionalCancelInRequest = false
      const publicProfile = document.body
      publicProfile.classList.toggle('noScroll')
    },
    // soy cliente y cancelo cuando estoy aprovado
    clickClientCancelInApproved () {
      this.clientCancelInApproved = true
      const publicProfile = document.body
      publicProfile.classList.toggle('noScroll')
    },
    clickHiddeClientCancelInApproved () {
      this.clientCancelInApproved = false
      const publicProfile = document.body
      publicProfile.classList.toggle('noScroll')
    },
    // soy profesional y cancelo cuando estoy aprovado
    clickProfessionalCancelInApproved () {
      this.professionalCancelInApproved = true
      const publicProfile = document.body
      publicProfile.classList.toggle('noScroll')
    },
    clickHiddeProfessionalCancelInApproved () {
      this.professionalCancelInApproved = false
      const publicProfile = document.body
      publicProfile.classList.toggle('noScroll')
    },
    // clickExitBooking () {
    //   const publicProfile = document.body
    //   publicProfile.classList.add('siScroll')
    // },
    exitVideocall () {
      this.showVideocall = false
      clearInterval(this.intervalVidecall)
    }
  }
}
</script>

<style>

.container-info-booking {
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
}

.container-info-modal {
  width: 90%;
  max-width: 470px;
  margin: 0 auto;
}
.professional-cancel-booking {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 7;
  background: #fff;
  padding: 27px 0px 80px 0px;
  overflow: auto;
}
.btn-videocall-disabled button {
  cursor: not-allowed;
  width: 120px;
  height: 44px;
  background: #e6e7fd;
  border: none;
  border-radius: 18px;
  font-family: "Basier Circle";
  font-weight: 500;
  font-size: 14px;
  color: #6e3dea;
  opacity: 0.7;
  /* transition: all 0.1s ease-in-out 0s; */
}
.btn-videocall button {
  cursor: pointer;
  width: 120px;
  height: 44px;
  background: linear-gradient(92.24deg, #6e3dea, #9a55f2);
  border: none;
  border-radius: 18px;
  font-family: "Basier Circle";
  font-weight: 500;
  font-size: 14px;
  color: #ffff;
  /* transition: all 0.1s ease-in-out 0s; */
}
.transaction-cost {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ECECEC;
  border-top: 1px solid #ECECEC;
}

.exitVideocall {
  position: fixed;
  width: 100vw;
  height: 34px;
  top: 0;
  left: 0;
  background-color: #0C0D34;
  /* background-color: rgb(27, 235, 185); */
  z-index: 9;
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
}

.exitVideocall img {
  height: 16px;
  margin-top: 7px;
  margin-left: 55%;
  opacity: 0.7;
}
.noScroll {
  overflow: hidden!important;
}
.siScroll {
  overflow: auto!important;
}
#booking_completed input {
  border: none;
  background: transparent;
  width: 13px;
  font-size: 12px!important;
  font-weight: bold;
  height: 18px!important;
  color: #00D6CB!important;
}
/* #booking_completed input:hover {
  border: none;
} */
#booking_completed .rate .rate-item.set-on .icon, .rate .rate-item.set-half .is-half {
  color: #00D6CB!important;
}

.image-avatar-booking {
  object-fit: cover;
  border-radius: 60px;
  height: 40px;
  width: 40px;
}

.name-professional {
  text-transform: capitalize;
  font-size: 17px;
}
.container-content-booking {
  width: 85%;
  max-width: 592px;
  margin: 115px auto 35px auto;
}

.status-text {
  font-size: 11px;
  border-radius: 15px;
  padding: 5px 8px;
  color: #0d1239;
}

.date-booking {
  font-size: 35px;
  max-width: 130px;
  line-height: 39px;
  margin-top: 10px;
}

.top-line-gray {
  border-top: 1px solid #ECECEC;
}

.language-booking {
  font-size: 13px;
}

.price-booking {
  font-size: 22px;
}

.hour-booking {
  font-size: 14px;
}

.time-for-videocall, .duration-videocall-completed {
  font-size: 13px;
}

.bottom-line-gray {
  border-bottom: 1px solid #ECECEC;;
}

.name-professional , .name-client {
  font-size: 16px;
  text-transform: capitalize;
}

.title-client-booking , .title-professional-booking {
  font-size: 13.5px;
}

.cancel-button {
  display: flex;
  align-items: center;
  font-size: 13px;
  text-decoration: underline;
}

.cancel-button:hover, .cancel-button:active, .cancel-button:focus {
  color:#d1d1d1;
}

.cancel-button-disabled {
  font-size: 13px;
  color: #D0CFCF;
  text-decoration: underline;
}

.link-videocall, .link-add-calendar {
  text-decoration: underline;
}

.title-notification {
  font-size: 35px;
  max-width: 201px;
}

.sub-title-notification {
  font-size: 21px;
}

.max-width-subtitle {
  max-width: 280px;
}
.paragraph-max-width {
  max-width: 380px;
  font-size: 15px !important;
  line-height: 24px !important;
}

.label-booking-canceled {
  font-size: 13px;
}
.title-notification-large {
  font-size: 35px;
  max-width: 240px;
}
.info-canceled {
  text-decoration: line-through;
}

.title-medium-notification {
  font-size: 22px;
  line-height: 38px;
}

.modal-container {
  width: 100%;
  background-color: #fff;
  height:100vh;
  position: fixed;
  z-index: 6;
  overflow: auto;
}

.general-container {
  width: 100%;
}

.container-buttons-reschedule {
  max-width: 450px;
}

@media (min-width: 959px) {
  .modal-container {
    width: 45%;
    max-width: 504px;
    height:100vh;
    position: fixed;
    z-index: 6;
    top: 0;
    right: 0;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }
  .container-content-booking {
    margin: 155px auto 35px auto;
  }
}
</style>
