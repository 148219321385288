<template>
  <div class="containerRating is-flex">
    <!-- Close Modal -->
    <div class="image-feedback is-hidden-touch">
      <img src="https://emituscdn.com/feedback-bacground.svg" alt="">
    </div>
    <div class="content-feedback">
      <div class="content-feedback-sub">
        <a @click="closeModal">
          <div class="button-close has-text-right close">
            <svg width="474" height="720" viewBox="0 0 474 720" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="-3" width="474" height="729" fill="black"/>
              <path d="M74.5596 506.504L57 545.172V545.733H63.2176L67.7271 535.477H88.6347L93.1441 545.733H99.4984V545.172L81.8705 506.504H74.5596ZM78.0442 511.94H78.3175L86.7216 531.106H69.6402L78.0442 511.94Z" fill="white"/>
              <path d="M142.418 545.733C151.915 545.733 157.655 541.53 157.655 534.413C157.655 529.817 155.058 526.679 149.866 524.942V524.718C153.897 523.26 155.878 520.571 155.878 516.704C155.878 510.371 150.139 506.504 141.94 506.504H122.74V545.733H142.418ZM128.616 510.876H141.257C146.723 510.876 149.797 512.949 149.797 516.704C149.797 521.131 146.244 523.316 140.505 523.316H128.616V510.876ZM128.616 527.576H141.735C147.953 527.576 151.505 529.929 151.505 534.413C151.505 538.728 147.543 541.305 141.735 541.305H128.616V527.576Z" fill="white"/>
              <path d="M201.078 506C194.041 506 188.711 507.849 185.159 511.604C181.674 515.303 179.898 520.122 179.898 526.119C179.898 532.115 181.674 536.934 185.227 540.689C188.78 544.388 194.041 546.237 201.078 546.237C208.116 546.237 213.377 544.388 216.93 540.633C220.483 536.878 222.259 532.059 222.259 526.119C222.259 520.122 220.483 515.303 216.93 511.604C213.377 507.849 208.116 506 201.078 506ZM201.078 510.595C206.339 510.595 210.097 512.052 212.489 515.023C214.88 517.993 216.11 521.691 216.11 526.119C216.11 530.546 214.88 534.244 212.489 537.215C210.097 540.185 206.339 541.642 201.078 541.642C195.817 541.642 191.991 540.185 189.6 537.215C187.208 534.244 186.047 530.546 186.047 526.119C186.047 518.161 189.668 510.595 201.078 510.595Z" fill="#7AD398"/>
              <path d="M80.7831 604.437C87.1378 604.437 92.0576 602.868 95.6108 599.674C99.1639 596.479 100.941 592.108 100.941 586.616V583.31H82.1497V587.737H95.2691C95.2691 591.268 93.9708 594.182 91.4426 596.479C88.9144 598.777 85.3612 599.898 80.7831 599.898C75.6583 599.898 71.9002 598.441 69.5086 595.527C67.1854 592.557 66.0238 588.858 66.0238 584.431C66.0238 576.529 69.6453 568.796 80.7831 568.796C88.6411 568.796 93.3559 572.158 93.9708 577.258H100.121C99.6423 573.279 97.729 570.14 94.3808 567.787C91.0326 565.377 86.4545 564.2 80.7831 564.2C74.0867 564.2 68.8936 566.05 65.2721 569.692C61.6506 573.335 59.874 578.21 59.874 584.431C59.874 590.539 61.6506 595.415 65.2721 599.057C68.8936 602.644 74.0184 604.437 80.7831 604.437Z" fill="white"/>
              <path d="M137.682 564.705L120.121 603.372V603.933H126.339L130.849 593.677H151.758L156.267 603.933H162.622V603.372L144.993 564.705H137.682ZM141.166 570.14H141.44L149.844 589.306H132.762L141.166 570.14Z" fill="white"/>
              <path d="M77.8639 662.132C84.7574 662.132 90.0128 660.283 93.6302 656.64C97.3158 652.997 99.1586 648.29 99.1586 642.518C99.1586 636.522 97.2476 631.758 93.4937 628.228C89.7398 624.697 84.5526 622.904 77.8639 622.904H62.0293V662.132H77.8639ZM67.9673 627.387H77.2496C82.4368 627.387 86.3272 628.844 88.989 631.702C91.6509 634.56 93.0159 638.147 93.0159 642.518C93.0159 646.945 91.6509 650.588 88.989 653.446C86.3272 656.304 82.4368 657.705 77.2496 657.705H67.9673V627.387Z" fill="white"/>
              <path d="M141.927 662.636C153.394 662.636 160.219 657.257 160.219 648.29V622.904H154.213V648.178C154.213 654.399 150.05 658.153 141.927 658.153C133.942 658.153 129.71 654.399 129.71 648.178V622.904H123.772V648.29C123.772 657.257 130.666 662.636 141.927 662.636Z" fill="white"/>
              <path d="M202.221 662.636C213.346 662.636 220.103 658.377 220.103 651.372C220.103 645.376 215.599 641.621 206.521 640.108L200.037 638.987C194.645 638.035 191.915 636.073 191.915 633.159C191.915 628.956 195.328 626.883 202.153 626.883C209.251 626.883 212.937 629.629 213.21 634.056H219.284C219.216 630.581 217.646 627.779 214.575 625.65C211.504 623.464 207.408 622.399 202.289 622.399C191.847 622.399 185.704 626.49 185.704 633.159C185.704 638.987 189.936 642.238 198.399 643.751L205.361 644.984C210.889 645.936 213.892 647.786 213.892 651.372C213.892 655.912 210.002 658.209 202.221 658.209C194.85 658.209 190.55 655.295 190.209 650.364H184.134C184.407 657.929 190.823 662.636 202.221 662.636Z" fill="white"/>
              <circle cx="245.97" cy="657.608" r="5.02963" fill="#7AD398"/>
            </svg>
          </div>
        </a>

        <!-- Content -->
        <div>
          <p class="text-basier mb-4">Puntuación</p>
          <span class="text-nunito">¿Cómo fue tu experiencia?</span>
          <b-rate
            class="mt-6"
            v-model="rate"
            :icon-pack="packs"
            :icon="icons"
            :max="maxs">
          </b-rate>
          <b-field>
            <b-input
              placeholder="Escribe aquí..."
              v-model="review"
              maxlength="200"
              type="textarea"> </b-input>
          </b-field>
          <br>
          <button
            class="button-primary-mini text-basier-medium"
            @click="deliveredSession"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import BookingService from '@/services/BookingService'

export default {
  name: 'Rating',

  // DATA

  data () {
    return {
      user: null,
      booking: null,
      rate: null,
      maxs: 5,
      packs: 'mdi',
      icons: 'star',
      review: null
    }
  },

  // CREATED
  async created () {
    this.notify.$on('updateUser', (user) => {
      this.user = user
      // console.log(this.user, 'usuario logeado')
    })

    // Get booking
    const idToken = await firebase.auth().currentUser.getIdToken(true)
    const myBooking = await BookingService.getBooking(idToken, this.$route.params.idBooking)
    this.booking = myBooking.data
  },

  // MOUNTED
  async mounted () {
    // Ask for data to App
    await this.notify.$emit('giveMeData')
  },

  // COMPUTED
  computed: {
    typeOfUser: function () {
      if (this.booking.professional_id === this.user._id) {
        return 'professional'
      } else if (this.booking.client_id === this.user._id) {
        return 'client'
      } else {
        return ''
      }
    }
  },

  // METHOSDS
  methods: {
    // Close modal
    closeModal () {
      this.notify.$emit('closeRating')
      this.notify.$emit('refreshStatusBooking')
    },

    // Delivered online session successfully
    async deliveredSession () {
      try {
        const idToken = await firebase.auth().currentUser.getIdToken(true)
        const ratingObj = {
          rate: this.rate,
          review: this.review
        }

        // Professional rates Session
        if (this.typeOfUser === 'professional') {
          const response = await BookingService.professionalRateSession(idToken, this.$route.params.idBooking, ratingObj)

          if (response.data.msg === 'Session rated successfully') {
            this.closeModal()
            this.notify.$emit('exitVideocall')
          }

        // Client rates Session
        } else if (this.typeOfUser === 'client') {
          const response = await BookingService.clientRateSession(idToken, this.$route.params.idBooking, ratingObj)
          if (response.data.msg === 'Session rated successfully') {
            this.closeModal()
            this.notify.$emit('exitVideocall')
          }
        }
      } catch (err) {
        this.danger(err.response.data.msg)
      }
    },

    // Success Toast
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-danger'
      })
    }

  }
}
</script>

<style>

.image-feedback img {
  height: 100vh;
  /* object-fit: cover; */
}
.content-feedback {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin: 0 auto;
}
.content-feedback-sub {
  width: 85%;
}
.content-feedback-sub p {
  font-size: 22px;
}
.content-feedback .rate .rate-item {
  /* width: 50%; */
  margin-right: 15px;
}
.image-feedback {
  /* width: 50%; */
  height: 100vh;
}
.containerRating {
  /* background-color: #2b196c; */
  height: 100vh;
  width: 100%;
}
@media (min-width: 1024px) {
  .content-feedback {
    width: 51%;
    max-width: 371px;
  }
}

</style>
