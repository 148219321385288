<template>
  <div class="container-booking mt-4">
    <div class="mt-6">
      <!-- <h3 class="" style="color: #0d1239">4. Confirm Booking</h3> -->

      <!-- Purchase info -->
      <div
        class="is-flex mt-5 is-justify-content-space-between py-4"
        style="border-top: 1px solid #ececec; border-bottom: 1px solid #ececec"
      >
        <div class="is-flex">
          <div class="mr-4">
            <figure class="image m-center" style="width: 40px; height: 40px">
              <img class="img-radius-intro" :src="avatar" />
            </figure>
          </div>
          <div class="mr-4">
            <div class="field pb-4">
              <p class="is-pulled-left text-basier-bold" style="color: #0d1239">
                Consulta con
              </p>
            </div>
            <div class="field pb-4">
              <p
                class="text-nunito is-pulled-left"
                style="color: #000000; font-size: 14px; font-weight: 300"
              >
                {{ name }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="ml-4 is-flex is-flex-direction-column is-align-items-flex-end"
        >
          <div>
            <p
              class="text-nunito is-pulled-left"
              style="color: #000000; font-weight: 600; font-size: 17px"
            >
              <!-- {{ price }} {{currentBooking.currency === 'USD' ? '$' : '€'}} -->
              {{ price }} {{currentBooking.currency === 'USD' ? '$' : '€'}}
            </p>
          </div>
          <div>
            <p
              class="text-nunito is-pulled-left"
              style="color: #6b6c7d; font-size: 12px; font-weight: 300"
            >
              <img src="@/assets/images/booking/watch.svg" alt="time" />
              {{ minutes }} min
            </p>
          </div>
        </div>
      </div>

      <!-- C2A -->
      <div
        class="is-flex is-flex-direction-column is-align-items-center"
        style="width: 204px; margin: 21px auto 40px auto;"
      >
        <div>
          <p class="text-basier-bold mb-4" style="color: #0d1239">Cancelled Booking</p>
          <div class="is-flex mb-2">
            <img class="mr-2" src="https://emituscdn.com/calendar-vector.svg" alt="" width="14px">
            <span style="text-decoration: line-through">{{currentBooking.date}}</span>
          </div>
          <div class="is-flex">
            <img class="mr-2" src="https://emituscdn.com/clock-vector.svg" alt="" width="15px">
            <span style="text-decoration: line-through">{{currentBooking.time}}</span>
          </div>
        </div>
        <br>
        <br>
        <br>
        <div class="mb-6">
          <p class="text-basier-bold mb-4" style="color: #0d1239">New booking</p>
          <p class="mb-2"><span class="text-nunito-SemiBold">Date:</span> {{newDurationInit}} - {{newDurationEnd}}</p>
          <p class="mb-2"><span class="text-nunito-SemiBold">Time:</span> {{newDate}}</p>
          <p class="mb-2"><span class="text-nunito-SemiBold">Paid:</span> {{price}} {{currentBooking.currency === 'USD' ? '$' : '€'}}</p>
          <p class="mb-2"><span class="text-nunito-SemiBold">Language:</span>  {{currentBooking.language}}</p>
        </div>
        <b-button
          v-if="!loader"
          @click="payNow"
          class="
            button-primary-big
          "
          type="is-primary"
          >Confirm</b-button
        >
        <div v-if="loader">
          <Loading/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/modals/loader.vue'
import dayjs from 'dayjs'
export default {
  components: {
    Loading
  },
  name: 'paymenWall',
  props: {
    currentBooking: Object,
    startBook: Date,
    endBook: Date
  },
  data () {
    return {
      avatar: null,
      name: null,
      minutes: null,
      price: null,
      newDate: new Date(),
      newDurationInit: null,
      newDurationEnd: null,
      loader: false
    }
  },
  created () {
    this.notify.$on('sendSummary', summary => {
      this.avatar = summary.avatar
      this.name = summary.name
      this.minutes = summary.minutes
      this.price = summary.price
    })
    this.notify.$emit('giveMeSummary')
    this.newDate = dayjs(this.startBook).format('D MMMM YY')
    this.newDurationInit = dayjs(this.startBook).format('HH:mm')
    const endDuration = dayjs(this.endBook).toDate()
    this.newDurationEnd = dayjs(endDuration).format('HH:mm')
  },
  methods: {
    payNow () {
      this.loader = true
      this.notify.$emit('payNow')
    }
  }
}
</script>

<style>

</style>
