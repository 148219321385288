<template>
  <main class="wrapper">

    <!-- Finish Call -->
    <div
      v-if="status === 'call'"
      class="finish">
      <button
        @click="rateVideocall"
        class="finishButton"
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          class="mr-4">
          <path d="M7.2876 1.05371H9.3476C9.62077 1.05371 9.88275 1.16223 10.0759 1.35539C10.2691 1.54855 10.3776 1.81054 10.3776 2.08371V9.29371C10.3776 9.56688 10.2691 9.82887 10.0759 10.022C9.88275 10.2152 9.62077 10.3237 9.3476 10.3237H7.2876" stroke="white" stroke-width="1.4284" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4.7124 8.26389L7.2874 5.68889L4.7124 3.11389" stroke="white" stroke-width="1.4284" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7.28742 5.68872H1.10742" stroke="white" stroke-width="1.4284" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span
          class="finishSpan"
        >
          Leave
        </span>
      </button>
    </div>

    <!-- Rating -->
    <b-modal
        v-model="showRating"
        full-screen
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal
        :can-cancel="false">

        <div>
          <Rating />
        </div>

    </b-modal>
  </main>
</template>

<script>
import DailyIframe from '@daily-co/daily-js'
import Rating from '@/components/modals/rating.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import BookingService from '@/services/BookingService'
import UserService from '@/services/UserService'

export default {
  name: 'VideocallRoom',

  // DATA

  data () {
    return {
      status: 'lobby',
      callFrame: null,
      validRoomURL: false,
      roomError: false,
      showRating: false,
      booking: null,
      user: null
    }
  },

  // CREATED
  async created () {
    // Close modal
    this.notify.$on('close', () => {
      this.showRating = false
    })

    // Close rating
    this.notify.$on('closeRating', () => {
      this.showRating = false
    })

    // Get booking
    const idToken = await firebase.auth().currentUser.getIdToken(true)

    await UserService.getCurrentUser(idToken)
      .then(user => {
        this.user = user.data
      })

    const myBooking = await BookingService.getBooking(idToken, this.$route.params.idBooking)
    this.booking = myBooking.data
    this.joinRoom(this.booking.roomurl)
  },

  // COMPONENTS
  components: {
    Rating
  },

  // COMPUTED
  computed: {
    token: function () {
      if (this.user._id === this.booking.professional_id) {
        return this.booking.tokenProfessional
      } else if (this.user._id === this.booking.client_id) {
        return this.booking.tokenClient
      } else {
        return null
      }
    }
  },

  // METHODS
  methods: {

    // Rating
    rateVideocall () {
      this.showRating = true
      this.callFrame.destroy()
      this.status = 'lobby'
      this.joinRoom(this.booking.roomurl)
    },

    // Join a room
    joinRoom (url) {
      if (this.callFrame) {
        this.callFrame.destroy()
      }

      // Daily event callbacks
      const logEvent = (ev) => console.log(ev)
      const goToLobby = () => (this.status = 'lobby')
      const goToCall = () => (this.status = 'call')
      const leaveCall = () => {
        if (this.callFrame) {
          this.status = 'lobby'
          this.callFrame.destroy()
        }
      }

      // Make the call fullscreen
      const callFrame = DailyIframe.createFrame({
        iframeStyle: {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          'z-index': 7
        }
      })

      this.callFrame = callFrame

      // Add event listeners and join call
      callFrame
        .on('loaded', logEvent)
        .on('started-camera', logEvent)
        .on('camera-error', logEvent)
        .on('joining-meeting', goToLobby)
        .on('joined-meeting', goToCall)
        .on('left-meeting', leaveCall)
        .setTheme({
          light: {
            colors: {
              accent: '#6E3DEA',
              accentText: '#FFFFFF',
              background: '#071D3A',
              backgroundAccent: '#222E5E',
              baseText: '#FFFFFF',
              border: '#26346B',
              mainAreaBg: '#031429',
              mainAreaBgAccent: '#071D3A',
              mainAreaText: '#FFFFFF',
              supportiveText: '#FFFFFF'
            }
          },
          dark: {
            colors: {
              accent: '#6E3DEA',
              accentText: '#FFFFFF',
              background: '#071D3A',
              backgroundAccent: '#222E5E',
              baseText: '#FFFFFF',
              border: '#26346B',
              mainAreaBg: '#031429',
              mainAreaBgAccent: '#071D3A',
              mainAreaText: '#FFFFFF',
              supportiveText: '#FFFFFF'
            }
          }
        })

      callFrame.join(
        {
          url,
          showFullscreenButton: true,
          token: this.token
        })
    }
  },

  // DESTROY
  destroyed () {
    this.callFrame.destroy()
    window.removeEventListener('loaded', this.myEventHandler)
    window.removeEventListener('started-camera', this.myEventHandler)
    window.removeEventListener('camera-error', this.myEventHandler)
    window.removeEventListener('joining-meeting', this.myEventHandler)
    window.removeEventListener('joined-meeting', this.myEventHandler)
    window.removeEventListener('left-meeting', this.myEventHandler)
  }
}
</script>

<style scoped>
.finish {
  position: fixed;
  width: 150px;
  height: 58px;
  background-color: #071D3A;
  /* background-color: #121a24; */
  right: 0;
  bottom: 0;
  z-index: 8;
}
.finishButton {
  background-color: #FF2424;
  border-color: #FF2424;
  color: rgb(255, 255, 255);
  padding: 7px 15px 8px;
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 12.3px;
  box-shadow: transparent 0px 0px 0px 0px;
  color: var(--button-color);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: GraphikMedium, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
  margin: 12px 0px 0px 28px;
  pointer-events: all;
  position: relative;
  text-decoration: none;
  transition: background 200ms ease 0s, border 200ms ease 0s, box-shadow 200ms ease 0s;
  white-space: nowrap;
}
.finishSpan {
  font-family: GraphikMedium, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: inherit;
  margin: 0px;
  color: rgb(255, 255, 255);
}
.wrapper {
  background-color: var(--grey-lightest);
  height: 100%;
  display: flex;
  align-items: center;
}
.home {
  flex: 1;
}
.error {
  color: var(--red-dark);
}
.call-container {
  display: flex;
  max-width: 1200px;
  margin: auto;
  flex: 1;
  flex-wrap: wrap;
}
.call-container.hidden {
  flex: 0;
}
#call {
  flex: 1;
  margin-top: 24px;
}
.start-call-container {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  margin: auto;
}
.start-call-container .subtext {
  margin: 8px 0 0;
}
.start-call-container input {
  margin-top: 8px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid var(--grey);
  padding: 0 16px;
}
.start-call-container button {
  color: var(--dark-blue);
  background: var(--teal);
  border: 1px solid transparent;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 16px;
}
.start-call-container input[type="submit"] {
  color: var(--dark-blue);
  background: var(--teal);
  border: 1px solid transparent;
  padding: 6px 16px 8px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 16px;
}
button:disabled,
.start-call-container input[type="submit"]:disabled {
  cursor: not-allowed;
  background: var(--white);
  border: 1px solid var(--grey);
}
label {
  opacity: 0;
  font-size: 1px;
}
</style>
